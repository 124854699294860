import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {  faHome, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Group } from 'src/app/models/group';
import { InitService } from 'src/app/services/init.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {


  homeIcon=faHome;
  addIcon=faPlus;

  constructor(private navigationService:NavigationService,private initService:InitService) { }

  ngOnInit() {
    this.navigationService.currentPageInd=0;
  
  }

  openChat(ind:number):void{
    this.navigationService.chatViewIsOpen=true;
    this.navigationService.selectedChatInd=ind;
  }

  openRandomChat():void{
    this.navigationService.randomChatViewIsOpen=true;
  }

  getShowTutorial(): boolean {
    return this.initService.showTutorial;
  }

  setShowCreateGroupPopup(state:boolean):void{
   this.navigationService.showCreateGroupPopup=state;
    this.navigationService.showJoinWebPopup=state;
  }
  getShowCreateGroupPopup():boolean{
    return this.navigationService.showCreateGroupPopup;
  }
  getShowJoinWebPopup():boolean{
    return this.navigationService.showJoinWebPopup;
  }


  getAllGroupsData():Group[]{
    return this.initService.allGroupsData;
  }

  getChatViewIsOpen():boolean{
    return this.navigationService.chatViewIsOpen;
  }
  getRandomChatViewIsOpen():boolean{
    return this.navigationService.randomChatViewIsOpen;
  }

  getHomeIsLoaded():boolean{
    return this.initService.homeIsLoaded;
  }

  getNickname():string{
    return this.initService.nickname;
  }

  getIsApp():boolean{
    return this.navigationService.isApp;
  }


}
