import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HomePageRoutingModule } from './home-routing.module';

import { HomePage } from './home.page';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { TutorialComponent } from './tutorial/tutorial.component';
import { SwiperModule } from 'swiper/angular';
import { CreateGroupPopupComponent } from './create-group-popup/create-group-popup.component';
import { ChatViewComponent } from './chat-view/chat-view.component';
import { JoinWebPopupComponent } from './join-web-popup/join-web-popup.component';
import { RandomChatViewComponent } from './random-chat-view/random-chat-view.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HomePageRoutingModule,
    SharedModule,
    SwiperModule,
    FontAwesomeModule
  ],
  declarations: [
    HomePage,
    TutorialComponent,
    CreateGroupPopupComponent,
    JoinWebPopupComponent,
    ChatViewComponent,
    RandomChatViewComponent,
  ]
})
export class HomePageModule {}
