import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {  faChartLine,   faGem,   faUsers, } from '@fortawesome/free-solid-svg-icons';
import { InitService } from 'src/app/services/init.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  icons:any[]=[
    faUsers,
    faChartLine,
   // faGears,
    //faGem,
  ];
  constructor(private ns: NavigationService,private initService:InitService, private router: Router) { }

  ngOnInit() {}

  navigate(ind: number): void {
    this.ns.currentPageInd = ind;
    if(ind==0){
      this.ns.chatViewIsOpen=false;
    }
    this.router.navigate(["/" + this.ns.pages[ind]]);
  }
  getCurrentPageInd(): number {
    return this.ns.currentPageInd;
  }
  showFade():boolean{
    return false;
  }
  showNavbar():boolean{
    return !this.initService.showTutorial;
    }
}
