import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { InitService } from 'src/app/services/init.service';
import { TextHelperService } from 'src/app/services/text-helper.service';
import Swiper, { Pagination, SwiperOptions } from 'swiper';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit {
  config: SwiperOptions = {
    slidesPerView: 1,
    navigation: true,
    pagination: true,
    scrollbar: { draggable: true },
  };

  nickname:string="";
  formErrorText:string="";

  constructor(private textHelper:TextHelperService, private apiService:ApiService, private initService:InitService) { }

  ngOnInit() {
    Swiper.use([Pagination]);
  }

  start():void{
    this.nickname=this.textHelper.convertToNormalChars(this.nickname);
    console.log(this.nickname);
    //this.initService.setShowTutorial();
    if(this.nickname.length>0){
      this.apiService.validate(this.nickname).subscribe((worked:boolean) => {
        if(worked){
          this.initService.nickname=this.nickname;
          this.initService.setNickname();
        }
        else{
          this.formErrorText="This nickname already exists!";
        }
        console.log(worked);
      });
    }else{
      this.formErrorText="This nickname already exists!";
    }
  }

  getAppName():string{
    return this.initService.appName;
  }

}
