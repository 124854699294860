import { Component, OnInit } from '@angular/core';
import { faBatteryEmpty, faChartLine, faTrophy, faUsers } from '@fortawesome/free-solid-svg-icons';
import { StatsData } from 'src/app/models/stats-data.model';
import { ApiService } from 'src/app/services/api.service';
import { InitService } from 'src/app/services/init.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.page.html',
  styleUrls: ['./statistics.page.scss'],
})
export class StatisticsPage implements OnInit {
  statsIcon = faChartLine

  allStats: StatsData[] = [];
  totalCompleted: number = 0;
  totalSkipped: number = 0;

  groupIcon = faUsers;
  vicoryIcon = faTrophy;
  defeatIcon = faBatteryEmpty;

  glowInd = 0;

  constructor(private apiService: ApiService, private navigationService: NavigationService, private initService: InitService) { }

  ngOnInit() {
    this.navigationService.currentPageInd = 1;
    setTimeout(() => {
      this.getStatsData();
    }, 500);

    setInterval(() => {
      this.glowInd = (this.glowInd + 1) % 3
    }, 1500);

  }

  getStatsData(): void {
    for (let i = 0; i < this.initService.allGroupsData.length; i++) {
      this.apiService.getStatsData(this.initService.allGroupsData[i].groupID).subscribe((statsData: StatsData) => {
        this.allStats.push(statsData);
        console.log(this.allStats);
        if (this.allStats.length == this.getGroupsAmount()) {
          for (let i2 = 0; i2 < this.allStats.length; i2++) {
            this.totalCompleted += this.allStats[i2].completed;
            this.totalSkipped += this.allStats[i2].skipped;
          }
        }
      });
    }
  }

  getGroupsAmount() {
    return this.initService.allGroupsData.length;
  }

}
