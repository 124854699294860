import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { Group } from '../models/group';
import { ApiService } from './api.service';
import { NavigationService } from './navigation.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  appName: string = "Challenger";
  showTutorial: boolean = false;
  homeIsLoaded: boolean = false;
  nickname: string = "";
  allGroupsData: Group[] = [];

  internetConnected:boolean=true;

  constructor(private apiService: ApiService,private navigationService:NavigationService, private storageService: StorageService) {
    
    this.getCurrentNetworkStatus();
    setTimeout(() => {
      //this.storageService.addData(0, "nickname", "") //has to be false
    }, 1);
    this.initHome(); // to show tutorial uncomment this
  }

  async getCurrentNetworkStatus() {
 
    Network.addListener('networkStatusChange', status => {
     this.internetConnected=status.connected;
      if(this.internetConnected){
        this.initHome();
      }
    });

    const status = await Network.getStatus();
    this.internetConnected=status.connected;
  };

  initHome(): void {
    if(!this.navigationService.isApp){
      this.showTutorial=false;
      this.homeIsLoaded=true;
    }else{
      this.storageService.getData(0, "nickname").subscribe(nickname => {
        this.nickname = nickname == null || nickname == "" ? "" : nickname; //uncomment
        if (this.nickname.length == 0) {
          this.showTutorial = true;
        } else {
          this.loadGroupData();
        }
        this.homeIsLoaded = true;
      });
    }
 
  }

  loadGroupData() {
    if(this.navigationService.isApp){
      this.apiService.loadGroupsData(this.nickname).subscribe((allGroupsData: Group[]) => {
        this.allGroupsData = []
        this.allGroupsData = allGroupsData;
        if (this.allGroupsData == null || this.allGroupsData.length == 0) {
          this.allGroupsData = [];
        }
      });
    }
  }

  loadGroupDataWeb(groupCode:string,groupName:string):void{
    this.apiService.loadGroupsDataWeb(groupCode,groupName).subscribe((allGroupsData: Group[]) => {
      this.allGroupsData = []
      this.allGroupsData = allGroupsData;
      if (this.allGroupsData == null || this.allGroupsData.length == 0) {
        this.allGroupsData = [];
      }else{
        
        this.navigationService.showJoinWebPopup=false;
      }
      console.log(allGroupsData);

    });
  }

  setNickname() {
    this.storageService.addData(0, "nickname", this.nickname) //has to be false
    this.showTutorial = false;
  }
}
