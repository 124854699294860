import { Injectable } from '@angular/core';
import { Challenge } from '../models/challenge.model';
import { Group } from '../models/group';
import { ApiService } from './api.service';
import { InitService } from './init.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root'
})
export class ChallengesService {

  allChallenges: Challenge[] = [];
  challengesLoaded: boolean = false;

  challenge: Challenge = {
    ID: 0,
    challenge: "",
    tags: "",
    level: 0,
  };


  emptyGroup: Group = {
    groupID: "",
    groupName: "",
    groupCode: "",
    selectedTags: "",
    hardness: 0,
    trophy: "",
    lastPlayedChallengeID: 0,
    groupMemberNicknames: [],
  }

  constructor(private apiService: ApiService, private initService: InitService, private navigationService: NavigationService) { }


  lookForStatusChanges(): void {
    this.apiService.getChallengeStatus(this.getSelectedGroup().groupID, this.challenge.ID).subscribe((status: number) => {
      if (status == 1 || status == 2) {
        this.getNextChallenge();
      }
    });
  }

  setChallengeStatus(status: number): void {
    this.apiService.setChallengeStatus(this.getSelectedGroup().groupID, this.challenge.ID, status).subscribe((worked: boolean) => {
      if (worked) {
        if (status == 1) { //gave up

        }
        if (status == 2) { //completed

        }
        this.getNextChallenge();
      }
    });
  }

  getNextChallenge(): void {
    if (this.navigationService.isApp) {
      this.initService.loadGroupData();
    } else {
      this.initService.loadGroupDataWeb(this.getSelectedGroup().groupCode, this.getSelectedGroup().groupName);
    }
    setTimeout(() => {
      this.calcCurChallenge();
    }, 1000);
  }

  loadChallenges(): void {
    this.apiService.loadChallenges().subscribe((allChallenges: Challenge[]) => {
      allChallenges != null && allChallenges.length > 0 ? this.allChallenges = allChallenges : this.allChallenges = [];
      this.calcCurChallenge();
      this.challengesLoaded = true;
    });
  }

  calcCurChallenge(): void {
    if (this.getSelectedGroup().groupName.length > 0) {
      let groupTags = this.getSelectedGroup().selectedTags;
      let hardness = this.getSelectedGroup().hardness;
      let lastPlayed = this.getSelectedGroup().lastPlayedChallengeID;

      let possibleChallenges: Challenge[] = [];

      for (let i = 0; i < this.allChallenges.length; i++) {
        if (this.allChallenges[i].level <= hardness) {
          for (let i2 = 0; i2 < groupTags.length; i2++) {
            if (groupTags.charAt(i2) == "1" && this.allChallenges[i].tags.charAt(i2) == "1") {
              possibleChallenges.push(this.allChallenges[i]);
              break;
            }
          }
        }
      }

      let allDone = true;

      for (let i = 0; i < possibleChallenges.length; i++) {
        if (possibleChallenges[i].ID > lastPlayed) {
          this.challenge = possibleChallenges[i];
          allDone = false;
          break;
        }
      }

      if (allDone) {//allDone
        this.apiService.resetLastPlayedChallengeID(this.getSelectedGroup().groupID).subscribe((worked: boolean) => {
          if (worked) {
            this.initService.allGroupsData[this.navigationService.selectedChatInd].lastPlayedChallengeID = 0;
            this.calcCurChallenge();
            console.log("now reset", worked);
          }
        });
      }
    }

  }

  getSelectedGroup(): Group {
    return this.initService.allGroupsData.length > 0 ? this.initService.allGroupsData[this.navigationService.selectedChatInd] : this.emptyGroup;
  }

}
