import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextHelperService {

  constructor() { }

  convertToNormalChars(text:string):string {
    let newStr:string="";
    for(let i=0;i<text.length;i++){
      let key=text.charCodeAt(i);
      if(((key >= 65 && key <= 90) || (key >= 95 && key <= 122) || (key>=48 && key<=57) || key==45 || key==46 || key==95) || text.charAt(i)==" " || text.charAt(i)=="ä"  || text.charAt(i)=="Ä" || text.charAt(i)=="ö" || text.charAt(i)=="Ö" || text.charAt(i)=="ü" || text.charAt(i)=="Ü"){
        newStr+=text.charAt(i);
      }
    }
    return newStr
  }
}
