import { Component, OnInit } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'src/app/services/api.service';
import { InitService } from 'src/app/services/init.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { TextHelperService } from 'src/app/services/text-helper.service';

@Component({
  selector: 'app-create-group-popup',
  templateUrl: './create-group-popup.component.html',
  styleUrls: ['./create-group-popup.component.scss'],
})
export class CreateGroupPopupComponent implements OnInit {

  createOrJoin: boolean = true;

  closeIcon = faXmark;

  groupName: string = "";
  groupCode: string = "";

  formErrorText: string = "";

  constructor(private textHelper:TextHelperService, private apiService: ApiService, private navigationService: NavigationService, private initService: InitService) { }

  ngOnInit() { }


  createOrJoinNow(): void {

    this.groupName=this.textHelper.convertToNormalChars(this.groupName);
    this.groupCode=this.textHelper.convertToNormalChars(this.groupCode);

    console.log(this.groupName, this.groupCode);
   
    if (this.createOrJoin) {
      if(this.groupName.length>0){
        this.apiService.createGroup(this.groupName, this.initService.nickname).subscribe((worked: boolean) => {
          if (worked) {
            this.initService.loadGroupData();
            this.navigationService.showCreateGroupPopup = false;
          } else {
            this.formErrorText = "something went wrong, please try again"
          }
        });
      }else{
        this.formErrorText = "To short, please try again!"
      }
    } else { //join
      console.log("join");
      console.log(this.groupName, this.groupCode, this.createOrJoin);

      if (this.groupCode.length > 0 && this.groupName.length > 0) {
        this.apiService.joinGroup(this.groupCode, this.groupName, this.initService.nickname).subscribe((worked: boolean) => {
          if (worked) {
            this.initService.loadGroupData();
            this.navigationService.showCreateGroupPopup = false;
          } else {
            this.formErrorText = "something went wrong, please try again"
          }
        });
      } else {
        this.formErrorText = "To short, please try again!"
      }
    }
  }

  setShowCreateGroupPopup(state: boolean): void {
    this.navigationService.showCreateGroupPopup = state;
  }

  getCreateOrJoinLabel(): string {
    return this.createOrJoin ? "Create" : "Join";
  }


}
