import { Component, OnInit } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { InitService } from 'src/app/services/init.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-join-web-popup',
  templateUrl: './join-web-popup.component.html',
  styleUrls: ['./join-web-popup.component.scss'],
})
export class JoinWebPopupComponent implements OnInit {

  closeIcon=faXmark;
  groupName:string;
  groupCode:string;
  constructor(private initService:InitService, private navigationService:NavigationService) { }

  ngOnInit() {}
  setShowCreateGroupPopup(state: boolean): void {
    this.navigationService.showCreateGroupPopup = state;
  }

  joinNow():void{
    if(this.groupCode.length>3 && this.groupName.length>1){
      this.initService.loadGroupDataWeb(this.groupCode,this.groupName);
    }
  }

  setShowJoinPopup(state:boolean):void{
    this.navigationService.showJoinWebPopup=state;
  }

}
