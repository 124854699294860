import { Component, OnInit } from '@angular/core';
import { faDiamond, faGem } from '@fortawesome/free-solid-svg-icons';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.page.html',
  styleUrls: ['./support.page.scss'],
})
export class SupportPage implements OnInit {

  donateIcon = faGem;
  donateLink: string = "https://buy.stripe.com/dR63eC65a7Rf5u88wE";
  constructor(private navigationService: NavigationService) { }

  ngOnInit() {
    this.navigationService.currentPageInd = 3;
  }

  openTab(): void {
    window.open(this.donateLink, "_blank");
  }

}
