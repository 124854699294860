import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  pages:string[]=["Home","Statistics"]
  currentPageInd:number=0;

  showCreateGroupPopup:boolean=false; //todo has to be false
  showJoinWebPopup:boolean=false; //todo has to be false

  chatViewIsOpen:boolean=false; //todo has to be false
  selectedChatInd:number=0;
  randomChatViewIsOpen:boolean=false;
  isApp=false; //todo has to be false;
  constructor() { 
    if (Capacitor.isNativePlatform()) {
      this.isApp = true;
    }
  }
}
