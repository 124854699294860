import { Component, Input, OnInit } from '@angular/core';
import { InitService } from 'src/app/services/init.service';

@Component({
  selector: 'app-shared-header',
  templateUrl: './shared-header.component.html',
  styleUrls: ['./shared-header.component.scss'],
})
export class SharedHeaderComponent implements OnInit {
  @Input() title:string="";
  @Input() icon:any;
  constructor(private initService:InitService ) { }

  ngOnInit() {}
  getShowHeader():boolean{
    return !this.initService.showTutorial;
  }
}
