import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Challenge } from '../models/challenge.model';
import { Group } from '../models/group';
import { StatsData } from '../models/stats-data.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  server: string = "https://www.challenger.versionx.ch";
  constructor(private httpClient: HttpClient) { }

  validate(nickname:string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.server}/api/validate.php`,{nickname:nickname});
  }

  createGroup(groupName:string,nickname:string ): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.server}/api/createGroup.php`,{groupName:groupName,nickname:nickname});
  }

  joinGroup(groupCode:string, groupName:string, nickname:string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.server}/api/joinGroup.php`,{groupName:groupName, groupCode:groupCode, nickname:nickname});
  }

  saveGroupSettings(groupID:string,selectedTags:string,hardness:number,trophy:string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.server}/api/saveGroupSettings.php`,{groupID:groupID,selectedTags:selectedTags,hardness:hardness,trophy:trophy});
  }

  leaveGroup(groupID:string,nickname:string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.server}/api/leaveGroup.php`,{groupID:groupID,nickname:nickname});
  }

  loadGroupsData(nickname:string): Observable<Group[]> {
    return this.httpClient.post<Group[]>(`${this.server}/api/loadGroupsData.php`, {nickname:nickname});
  }
  loadGroupsDataWeb(groupCode:string,groupName): Observable<Group[]> {
    return this.httpClient.post<Group[]>(`${this.server}/api/loadGroupsDataWeb.php`, {groupCode:groupCode,groupName:groupName});
  }
  loadChallenges(): Observable<Challenge[]> {
    return this.httpClient.post<Challenge[]>(`${this.server}/api/loadChallenges.php`, "");
  }
  setChallengeStatus(groupID:string,challengeID:number,status:number): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.server}/api/setChallengeStatus.php`,{groupID:groupID,challengeID:challengeID,status:status});
  }

  getChallengeStatus(groupID:string,challengeID:number): Observable<number> {
    return this.httpClient.post<number>(`${this.server}/api/getChallengeStatus.php`,{groupID:groupID,challengeID:challengeID});
  }
  
  getStatsData(groupID:string): Observable<StatsData> {
    return this.httpClient.post<StatsData>(`${this.server}/api/getStatsData.php`,{groupID:groupID});
  }

  
  resetLastPlayedChallengeID(groupID:string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.server}/api/resetLastPlayedChallengeID.php`,{groupID:groupID});
  }

}
