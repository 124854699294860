import { Component, OnInit } from '@angular/core';
import { faArrowLeft, faChevronRight, faEllipsisVertical, faFlag, faFlagCheckered, faGear, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Challenge } from 'src/app/models/challenge.model';
import { Group } from 'src/app/models/group';
import { ApiService } from 'src/app/services/api.service';
import { ChallengesService } from 'src/app/services/challenges.service';
import { InitService } from 'src/app/services/init.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { TextHelperService } from 'src/app/services/text-helper.service';

@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss'],
})
export class ChatViewComponent implements OnInit {

  backIcon = faArrowLeft;
  menuIcon = faGear;
  doneIcon = faFlagCheckered;

  tags: string[] = ["Mens", "Women", "Summer", "Winter", "Flirt", "Party", "Hot", "Bar", "Outside", "Social", "Public"];
  tagsSelected: boolean[] = [];
  hardness: number = 0;
  trophy: string = "";

  leaveOpened: boolean = false;

  chatSettingsIsOpen: boolean = false;//todo has to be false

  leaveIcon = faTrashAlt;
  leaveIcon2=faChevronRight;
  hour: number = 0;
  minute: number = 0;
  second: number = 0;

  dayTillChallenge: number = 0;
  hourTillChallenge: number = 0;
  minuteTillChallenge: number = 0;
  secondTillChallenge: number = 0;

  nextChallengeDay: any = new Date();
  showChallenge: boolean = true; //todo has to be false

  constructor(private textHelper:TextHelperService, private apiService: ApiService, private challengeService: ChallengesService, private initService: InitService, private navigationService: NavigationService) {
    for (let i = 0; i < this.tags.length; i++) {
      this.tagsSelected.push(false);
    }


    setTimeout(() => {
      for (let i = 0; i < this.tagsSelected.length; i++) {
        if (this.getSelectedGroup().selectedTags.charAt(i) == '1') {
          this.tagsSelected[i] = true;
        } else {
          this.tagsSelected[i] = false;
        }
      }
      this.hardness = this.getSelectedGroup().hardness;
      this.trophy=this.getSelectedGroup().trophy;
      this.challengeService.loadChallenges();

    }, 500);
    this.calcNextChallengeTime();
  }

  ngOnInit() {
    setInterval(() => {
      this.calcNextChallengeTime();
    }, 1000);

    setInterval(() => {
      if(this.navigationService.chatViewIsOpen && this.showChallenge){
        this.challengeService.lookForStatusChanges();
      }
    }, 2000);
  }

  calcNextChallengeTime() {
    let today = new Date();
    this.hour = today.getHours();
    this.minute = today.getMinutes();
    this.second = today.getSeconds();

    let todayEightOClock = new Date();

    todayEightOClock.setHours(20);
    todayEightOClock.setMinutes(0);
    todayEightOClock.setSeconds(0);

    let nextFriday = this.getNextDayOfWeek(todayEightOClock, 5);
    let nextSaturday = this.getNextDayOfWeek(todayEightOClock, 6);

    if (today.getDay() <= 5 || today.getDay() > 6) {
      this.nextChallengeDay = nextFriday;
    }
    if (today.getDay() == 6) {
      this.nextChallengeDay = nextSaturday;
    }

    this.dayTillChallenge = Math.max(0, this.nextChallengeDay.getDay() - today.getDay());
    this.hourTillChallenge = Math.max(0, this.nextChallengeDay.getHours() - this.hour - 1);
    this.minuteTillChallenge = Math.max(0, 60 - this.minute - 1);
    this.secondTillChallenge = Math.max(0, 60 - this.second);

    if (this.dayTillChallenge == 0 && this.hourTillChallenge == 0 && this.minuteTillChallenge == 0 && this.secondTillChallenge == 0) {
      this.showChallenge = true;
    }
  }

  getNextDayOfWeek(date, dayOfWeek) {
    var resultDate = new Date(date.getTime());

    resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);

    return resultDate;
  }

  leaveGroup(): void {
    this.apiService.leaveGroup(this.getSelectedGroup().groupID, this.initService.nickname).subscribe((worked: boolean) => {
      console.log(worked);
      this.initService.loadGroupData();
      this.navigationService.chatViewIsOpen = false;
      this.navigationService.selectedChatInd = 0;
    });

  }

  back(): void {
    let selectedTags = "";
    for (let i = 0; i < this.tagsSelected.length; i++) {
      if (this.tagsSelected[i]) {
        selectedTags += "1";
      } else {
        selectedTags += "0";
      }
    }
    this.trophy=this.textHelper.convertToNormalChars(this.trophy);
    console.log(this.trophy);

    this.apiService.saveGroupSettings(this.getSelectedGroup().groupID, selectedTags, this.hardness,this.trophy).subscribe((worked: boolean) => {
      console.log(worked);
      this.initService.loadGroupData();
      setTimeout(() => {
        this.challengeService.calcCurChallenge();
      }, 1000);
    });
    if (this.chatSettingsIsOpen) {
      this.chatSettingsIsOpen = false;
    } else {
      this.navigationService.chatViewIsOpen = false;
    }
    //this.showChallenge=false;

  }

  setChallengeStatus(status: number): void { //status 0=in arbeit, 1=gabe up, 2=completed
    this.challengeService.setChallengeStatus(status);
  }

  loadChallenges(): void {
    this.challengeService.loadChallenges();
    this.showChallenge = true;
  }

  getSelectedGroup(): Group {
    return this.challengeService.getSelectedGroup();
  }

  getNextChallengeDaySpelled(): string {
    let day = "";
    switch (this.nextChallengeDay.getDay()) {
      case 5: day = "Friday "; break;
      case 6: day = "Saturday "; break;
    }
    day += this.nextChallengeDay.getDate().toString() + "." + (this.nextChallengeDay.getMonth() + 1).toString()+"."+this.nextChallengeDay.getFullYear();
    return day;
  }

  getChallengesLoaded(): boolean {
    return this.challengeService.challengesLoaded;
  }

  getCurChallenge(): string {
    return this.challengeService.challenge.challenge;
  }

  getTrophy():string{
    return this.getSelectedGroup().trophy;
  }

  getMembers():string[]{
    return this.getSelectedGroup().groupMemberNicknames;
  }

  getIsApp():boolean{
    return this.navigationService.isApp;
  }
}
