import { Component, OnInit } from '@angular/core';
import { faAudioDescription, faHome } from '@fortawesome/free-solid-svg-icons';
import { InitService } from 'src/app/services/init.service';

@Component({
  selector: 'app-no-connection',
  templateUrl: './no-connection.component.html',
  styleUrls: ['./no-connection.component.scss'],
})
export class NoConnectionComponent implements OnInit {

  noConnectionIcon = faHome;

  constructor(private initService: InitService) { }

  ngOnInit() { }

  iHaveInternet(): void {
    this.initService.internetConnected = true;
  }

}
