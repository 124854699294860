import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedHeaderComponent } from './shared-header/shared-header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NoConnectionComponent } from './no-connection/no-connection.component';



@NgModule({
  declarations: [
    SharedHeaderComponent,
    NavbarComponent,
    NoConnectionComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
exports:[
  SharedHeaderComponent,
  NavbarComponent,
  NoConnectionComponent,
],
})
export class SharedModule { }
