import { Component, OnInit } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ChallengesService } from 'src/app/services/challenges.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-random-chat-view',
  templateUrl: './random-chat-view.component.html',
  styleUrls: ['./random-chat-view.component.scss'],
})
export class RandomChatViewComponent implements OnInit {
  challenge: string = "";
  backIcon = faArrowLeft;
  alreadyPlayedInds:number[]=[];

  specificChallenge:string="Benutze das Gelfinger Badi-Bounce Equipment und lass dich spicken/spicke jemanden in den Baldeggersee";
  showSpecificChallenge:boolean=true;

  constructor(private challengesService: ChallengesService, private navigationService: NavigationService) { }

  ngOnInit() {
    if (!this.challengesService.challengesLoaded) {
      this.challengesService.loadChallenges();
    }
    this.getRandomChallenge();

  }
  back(): void {
    this.navigationService.randomChatViewIsOpen = false;
  }
  next(): void {
    let randomVal=0;
    this.showSpecificChallenge=false;
    if(this.alreadyPlayedInds.length==this.challengesService.allChallenges.length){
      this.alreadyPlayedInds=[];
    }
    let iterations=0;
    while(true){
       randomVal = Math.round(Math.random() * (this.challengesService.allChallenges.length - 1));
      let found:boolean=false;
      for(let i=0;i<this.alreadyPlayedInds.length;i++){
        if(this.alreadyPlayedInds[i]==randomVal){
          found=true;
          break;
        }
      }
      if(!found){
        break;
      }
      if(iterations==1000){
        console.log("öksdjfasfjdsafj");
        break;
      }
    }
    this.alreadyPlayedInds.push(randomVal);
    this.challenge = this.challengesService.allChallenges[randomVal].challenge;
  }

  getRandomChallenge(): void {



    setTimeout(() => {
      if (this.challengesService.challengesLoaded) {
        this.challenge = "";
        let randomVal = Math.round(Math.random() * (this.challengesService.allChallenges.length - 1));
        this.challenge = this.challengesService.allChallenges[randomVal].challenge;

      } else {
        this.getRandomChallenge();
      }
    }, 500);
  }

}
